.header-container {
  width: 100%;
  font-weight: 300;
}

.header-content {
  height: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5.5rem;
}

@media only screen and (max-width: 950px) {
  .header-content {
    height: 8rem;
  }
}

.homepage-link {
  color: black;
  font-size: 1.8rem;
  text-decoration: none;
}
