.nav-container {
  position: absolute;
  margin: 0 1.5rem 0 5.5rem;
}

.nav-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  font-weight: 300;
  margin: 18px 0;
  padding: 0;
}

@media only screen and (max-width: 950px) {
  .nav-container {
    position: absolute;
    margin: 0 1.5rem 0 5.5rem;
  }

  .nav-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    font-weight: 300;
    flex-direction: row;
    padding: 0;
    margin-top: 0;
  }
}

.nav-list-item {
  margin: 0 0 1rem 0;
}

@media only screen and (max-width: 950px) {
  .nav-list-item {
    margin: 0 1rem 0 0;
  }
}

.nav-list-item a {
  color: black;
  text-transform: lowercase;
  text-decoration: none;
}

.nav-list-item a:hover {
  opacity: 0.6;
}

.nav-list-inner {
  margin: 1rem 0 0 1rem;
  display: flex;
  flex-direction: column;
}
