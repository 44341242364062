.lang-button {
    font-size: 1.4rem;
    font-weight: 300;
    margin: 0.2rem;
    border: 0;
    cursor: pointer;
    background: transparent;
    color: black;
    outline: none;
    opacity: .3;
  }

  .selected {
    opacity: 1;
  }