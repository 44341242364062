main {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.page {
  display: flex;
  flex-direction: column;
  margin: 18px 144px;
}

.page-sub {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 950px) {
  .page {
    margin: auto 3rem;
    margin-top: 5rem;
  }
}

.content {
  width: 61rem;
  margin: 0 auto 5% auto;
  font-size: 1.4rem;
  font-weight: 300;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 950px) {
  .content {
    /* width: 80%; */
    /* margin: 0 10% 4rem 10%; */
    width: calc(100% - 5rem);
    margin: 0 2.5rem;
    font-size: 1.4rem;
    font-weight: 300;
    display: flex;
    flex-direction: column;
  }
}

.works-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 1.4rem;
  font-weight: 300;
}

.works-list li {
  margin-bottom: 0.75rem;
}

.title {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 1.75rem;
}

.info {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.description {
  margin: 0.75rem 0;
  text-align: justify;
}

.description-quote {
  margin: 0.75rem 0;
  padding: 1rem 10%;
  font-style: italic;
  text-align: justify;
}

.description-author {
  font-size: 1.2rem;
  margin: 0.75rem 0;
  padding: 0rem 8rem 1rem;
  text-align: right;
}

.description-info {
  font-size: 1rem;
  margin: 0.75rem 0;
  text-align: justify;
}

.cv-header,
.cv-item,
.award,
.exhibition {
  margin: 0;
  padding: 0;
}

.award,
.exhibition {
  line-height: 1.6;
}

.year,
.cv-header {
  font-weight: 500;
  margin: 0.5rem 0;
  /* line-height: 2; */
}

.contact-item {
  margin-bottom: 1rem;
}

img {
  width: calc(100% - 4.75rem);
  min-width: 32rem;
  max-width: 61rem;
  margin: 0 auto 1rem auto;
  box-shadow: 3px 3px 14px 0px rgba(50, 50, 50, 0.2);
}
