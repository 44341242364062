html {
  font-family: sans-serif;
  font-size: 10px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  background: white;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: black;
}

.header-container {
  width: 100%;
  font-weight: 300;
}

.header-content {
  height: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5.5rem;
}

@media only screen and (max-width: 950px) {
  .header-content {
    height: 8rem;
  }
}

.homepage-link {
  color: black;
  font-size: 1.8rem;
  text-decoration: none;
}

.lang-button {
    font-size: 1.4rem;
    font-weight: 300;
    margin: 0.2rem;
    border: 0;
    cursor: pointer;
    background: transparent;
    color: black;
    outline: none;
    opacity: .3;
  }

  .selected {
    opacity: 1;
  }
.nav-container {
  position: absolute;
  margin: 0 1.5rem 0 5.5rem;
}

.nav-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  font-weight: 300;
  margin: 18px 0;
  padding: 0;
}

@media only screen and (max-width: 950px) {
  .nav-container {
    position: absolute;
    margin: 0 1.5rem 0 5.5rem;
  }

  .nav-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    font-weight: 300;
    flex-direction: row;
    padding: 0;
    margin-top: 0;
  }
}

.nav-list-item {
  margin: 0 0 1rem 0;
}

@media only screen and (max-width: 950px) {
  .nav-list-item {
    margin: 0 1rem 0 0;
  }
}

.nav-list-item a {
  color: black;
  text-transform: lowercase;
  text-decoration: none;
}

.nav-list-item a:hover {
  opacity: 0.6;
}

.nav-list-inner {
  margin: 1rem 0 0 1rem;
  display: flex;
  flex-direction: column;
}

.reviewer {
  margin-bottom: 0.2rem;
  margin-top: 1rem;
}
.review-link {
  text-decoration: underline;
}
main {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.page {
  display: flex;
  flex-direction: column;
  margin: 18px 144px;
}

.page-sub {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 950px) {
  .page {
    margin: auto 3rem;
    margin-top: 5rem;
  }
}

.content {
  width: 61rem;
  margin: 0 auto 5% auto;
  font-size: 1.4rem;
  font-weight: 300;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 950px) {
  .content {
    /* width: 80%; */
    /* margin: 0 10% 4rem 10%; */
    width: calc(100% - 5rem);
    margin: 0 2.5rem;
    font-size: 1.4rem;
    font-weight: 300;
    display: flex;
    flex-direction: column;
  }
}

.works-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 1.4rem;
  font-weight: 300;
}

.works-list li {
  margin-bottom: 0.75rem;
}

.title {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 1.75rem;
}

.info {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.description {
  margin: 0.75rem 0;
  text-align: justify;
}

.description-quote {
  margin: 0.75rem 0;
  padding: 1rem 10%;
  font-style: italic;
  text-align: justify;
}

.description-author {
  font-size: 1.2rem;
  margin: 0.75rem 0;
  padding: 0rem 8rem 1rem;
  text-align: right;
}

.description-info {
  font-size: 1rem;
  margin: 0.75rem 0;
  text-align: justify;
}

.cv-header,
.cv-item,
.award,
.exhibition {
  margin: 0;
  padding: 0;
}

.award,
.exhibition {
  line-height: 1.6;
}

.year,
.cv-header {
  font-weight: 500;
  margin: 0.5rem 0;
  /* line-height: 2; */
}

.contact-item {
  margin-bottom: 1rem;
}

img {
  width: calc(100% - 4.75rem);
  min-width: 32rem;
  max-width: 61rem;
  margin: 0 auto 1rem auto;
  box-shadow: 3px 3px 14px 0px rgba(50, 50, 50, 0.2);
}

